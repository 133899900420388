.Toast {
  display: flex;
  padding: 12px;

  &--success {
    background: var(--color-tertiary);
  }

  &--warning {
    background: var(--color-alert);
  }

  &__icon {
    color: var(--color-white);
    margin-right: 12px;
  }

  &__text {
    align-items: center;
    color: var(--color-white);
    display: flex;
  }
}
