@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.Loader {
  animation: spin 1s linear infinite;
}
