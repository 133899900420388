.DocImage {
  text-align: center;

  &__bordered {
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  &__img {
    margin-bottom: 20px;
    width: 100%;
  }
}
