.TextArea {
  border: 1px solid var(--color-gray-300);
  border-radius: 3px;
  height: 52px;
  padding: 6px;
  resize: none;
  transition: all 0.1s;

  &:active,
  &:focus {
    border-color: var(--color-secondary);
  }

  &:disabled {
    border-color: var(--color-gray-300);
    color: var(--color-gray-300);
  }

  &--error {
    border-color: var(--color-alert);
  }
}
