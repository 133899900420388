@import 'styles/font-mixins';
@import './shared';

.Faq {
  $self: &;
  min-height: 700px;
  padding-bottom: 120px;

  @media (max-width: 1366px) {
    padding: 0 48px 120px 48px;
  }

  @media (max-width: 600px) {
    padding: 0 24px 120px 24px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin-bottom: 96px;
  }

  &__sidemenu {
    margin-right: 32px;
  }

  &__content-main {
    width: 100%;
  }

  &__search {
    align-items: center;
    display: flex;
    margin-bottom: 57px;
    width: 100%;
  }

  &__search-clear {
    margin-right: 12px;
  }

  &__search-input {
    height: 40px;
    margin-right: 24px;
  }

  &__dropdown-card {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__topic {
    &:not(:last-child) {
      margin-bottom: 72px;
    }
  }

  &__topic-title {
    @extend %h1;
    font-weight: var(--font-weight-medium);
    margin-bottom: 32px;
  }

  &__title-container {
    margin: 48px 0 78px 0;
    padding: 0 45px;
    text-align: center;
  }

  &__title {
    @extend %display2;
    font-weight: var(--font-weight-semibold);
    margin-bottom: 15px;

    @media (max-width: 992px) {
      @include h1;
    }
  }

  &__subtitle {
    @extend %body1;
    color: var(--color-gray-500);
    font-weight: var(--font-weight-regular);
  }

  &__empty-questions {
    @extend %display2;
    font-weight: var(--font-weight-semibold);
    text-align: center;
  }
}
