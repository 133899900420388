:root {
  --color-alert: #ed5f74;
  --color-bg: #fff;
  --color-black: black;
  --color-blue-50: #f5fbff;
  --color-blue-100: #d6ecff;
  --color-blue-300: #7dabfb;
  --color-blue-400: #6c8eef;
  --color-blue-500: #556cd6;
  --color-blue-800: #212d63;
  --color-blue-900: #131f41;
  --color-blue-gray-1000: #212d63;
  --color-blue-gray-100: #cfd8dc;
  --color-blue-gray-200: #b0bec5;
  --color-blue-gray-300: #90a4ae;
  --color-blue-gray-400: #78909c;
  --color-blue-gray-500: #607d8b;
  --color-blue-gray-50: #eceff1;
  --color-blue-gray-600: #546e7a;
  --color-blue-gray-700: #455a64;
  --color-blue-gray-800: #37474f;
  --color-blue-gray-900: #263238;
  --color-cyan-500: #067ab8;
  --color-cyan-700: #06457a;
  --color-cyan-800: #093353;
  --color-cyan-900: #042235;
  --color-danger: #d30c15;
  --color-dev-blue: var(--color-dev-blue);
  --color-discord: #5865f2;
  --color-discord-hover: #3441ce;
  --color-github-blue: #0366d6;
  --color-gray-050: #f6f9fc;
  --color-gray-075: #eef2f6;
  --color-gray-100: #e3e8ee;
  --color-gray-1: #333333;
  --color-gray-2: #4f4f4f;
  --color-gray-200: #c1c9d2;
  --color-gray-300: #a3acb9;
  --color-gray-3: #828282;
  --color-gray-4: #e0e0e0;
  --color-gray-400: #8792a2;
  --color-gray-500: #697386;
  --color-gray-5: #ededed;
  --color-gray-600: #4f566b;
  --color-gray-6: #fafafa;
  --color-gray-700: #3c4257;
  --color-gray-800: #2a2f45;
  --color-gray-900: #1a1f36;
  --color-green-050: #ebffe9;
  --color-green-100: #cbf4c9;
  --color-green-300: #33c27f;
  --color-green-500: #09825d;
  --color-green-600: #0e6245;
  --color-neutral-075: #f1f6fa;
  --color-neutral-100: #f5f5f5;
  --color-neutral-200: #eeeeee;
  --color-neutral-300: #e0e0e0;
  --color-neutral-400: #bdbdbd;
  --color-neutral-500: #9e9e9e;
  --color-neutral-50: #fafafa;
  --color-neutral-600: #757575;
  --color-neutral-700: #616161;
  --color-neutral-800: #424242;
  --color-neutral-900: #1a2936;
  --color-orange-400: #e56f4a;
  --color-orange-500: #c44c34;
  --color-primary-disabled-fill: #fff9f9;
  --color-primary-disabled-text: #94c9ea;
  --color-primary-disabled: #b2d7ee;
  --color-primary-hover: #06457a;
  --color-primary-outline-bg: #ffe7e7;
  --color-primary: #042235;
  --color-quaternary: #0085ff;
  --color-red-400: #ed5f74;
  --color-sail-blue-300: #7dabf8;
  --color-sail-blue-900: #131f41;
  --color-sail-gray-100: #e3e8ee;
  --color-sail-gray-300: #a3acb9;
  --color-sail-gray-400: #8792a2;
  --color-sail-gray-500: #697386;
  --color-sail-gray-50: #f7fafc;
  --color-sail-gray-600: #4f566b;
  --color-sail-gray-700: #3c4257;
  --color-sail-gray-900: #042235;
  --color-sail-green-500: #09825d;
  --color-secondary-outline-bg: #deebff;
  --color-secondary: #556cd6;
  --color-tertiary: #1ea672;
  --color-white: white;
  --color-yellow-050: #fcf9e9;
  --color-yellow-400: #d97917;
  --color-yellow-500: #bb5504;
}
