.DocContainer {
  $self: &;
  padding: 32px 32px 16px;

  > *:not(.DocImage) {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }

  &__divider {
    height: 12px;
  }

  &__HashLink {
    display: none;
    padding-left: 8px;
  }

  &__last-updated {
    color: var(--color-sail-gray-500);
    font-size: 14px;
    font-weight: var(--font-weight-light);
  }

  &__page-title {
    font-size: 36px;
    font-weight: var(--font-weight-light);
    line-height: 1.2;

    &:hover {
      #{$self}__HashLink {
        display: inline;
      }
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  @media (max-width: 992px) {
    &__wrapper {
      align-items: flex-start;
      flex-direction: column;
      justify-content: start;
    }
  }
}
