.TotalAmount {
  white-space: nowrap;

  &__amount {
    color: var(--color-tertiary);
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-semibold);
    margin-top: 8px;
  }

  &__title {
    color: var(--color-sail-gray-500);
  }
}
