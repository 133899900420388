.TableParams {
  border: 1px solid #e6e6e6;
  margin: 0 0 20px;
  max-width: 100%;
  overflow-x: auto;

  &__thead {
    text-align: left;
  }

  &__tr {
    &--even {
      background-color: #f6f6f6;
    }
  }

  &__th {
    padding: 4px 8px;
  }

  &__td {
    min-width: 260px;
    padding: 2px 8px;

    &--param {
      font-family: var(--font-family-mono);
      font-weight: var(--font-weight-semibold);
      padding-right: 12px;
      white-space: nowrap;
    }

    &--sample-value {
      color: #9b9b9b;
      font-family: var(--font-family-mono);
      font-size: 13px;
    }

    &--description {
      width: 100%;
    }
  }

  &__data-type {
    color: #9b9b9b;
    font-family: var(--font-family-mono);
    font-size: 12px;
    font-weight: var(--font-weight-regular);
    padding-left: 6px;
  }
}
