.large-cta {
  margin-bottom: 50px;
  flex: 0 0 auto;
  height: 60px;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding-left: 40px;
  padding-right: 40px;
  background: linear-gradient(200deg, #0085ff 0.33%, #9340fa 85.46%);
  box-shadow: 0 15px 30px -15px rgb(90 92 248 / 60%);
  background-position: 0 0;
  transition: box-shadow 0.4s, padding-right 0.4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &::after {
    content: '→';
    position: relative;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
    height: 8px;
    width: 0;
    display: flex;
    align-items: center;
  }
  &:hover {
    transition: box-shadow 0.2s, padding-right 0.2s;
    box-shadow: 0 18px 30px -12px rgb(90 92 248 / 60%);
    &:after {
      opacity: 1;
      right: 0;
      width: 2px;
      margin-left: 10px;
    }
  }
}
