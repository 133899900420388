.DocEndpoint {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-semibold);
  margin: 32px 0 12px;

  @media (max-width: 1440px) {
    word-break: break-all;
  }
}
