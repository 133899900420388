.VideoPlayer {
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  position: relative;

  &__player {
    left: 0;
    position: absolute;
    top: 0;
  }
}
