input,
textarea {
  &:hover {
    border-color: var(--color-secondary);
  }

  &:disabled {
    background: var(--color-white);

    &::placeholder {
      color: var(--color-gray-300);
    }
  }

  &::placeholder {
    color: var(--color-gray-500);
  }

  // get rid of arrows/spinners for numeric inputs
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.FormFieldComponent {
  &__label {
    color: var(--color-gray-500);
    display: block;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .FormField {
    width: 100%;

    &__option {
      align-items: center;
      display: flex;
    }

    &__option-input {
      margin-right: 8px;
    }

    &__option-label {
      cursor: pointer;

      &--disabled {
        cursor: default;
      }
    }
  }

  &__error-message {
    color: var(--color-alert);
    display: block;
    font-size: var(--font-size-body5);
    height: 14px;
    margin: 3px 0 6px;
  }
}
