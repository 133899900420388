.checkmarks {
  ul {
    font-size: 16px;
    color: #949aa0;
    list-style: none;
    font-weight: 400;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    li {
      display: inline-block;
      padding-left: 20px;
      padding-bottom: 15px;
      margin: 0;
      // padding: 0;
      border: 0;
      font-size: 100%;

      vertical-align: baseline;

      &::before {
        content: '';
        display: inline-block;
        height: 8px;
        width: 11px;
        margin-right: 10px;
        background-image: url(/assets/svgs/checkmark-tiny.svg);
        opacity: 0.6;
      }
    }
  }
}
